.settings-page {
  display: flex;
  justify-content: flex-end;
  margin: 30px 0 10px 0;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 362px;
  }
  &__logout {
    justify-content: center;
    margin-right: 30px;
    max-width: 136px;
    span {
      margin: 0 6px;
    }
  }
}
