.copy-bt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  gap: 10px;
  border: 1px solid #43fcff;
  background: #090b32;
  color: #43fcff;
  &:hover {
    background: #43fcff;
    color: #fff;
    border: 1px solid #aefeff;
  }
}

.copy-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
}

.link-title {
  margin-top: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &__divider {
    width: 100%;
    height: 1px;
    background: #323054;
  }
  &__text {
    min-width: 200px;
    margin: 0 40px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #8186af;
  }
}

.link-sharing {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 38px;
    background: #fa544d;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
  }
}
