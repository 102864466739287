.slader-item-arrows .carousel .control-prev.control-arrow,
.card .carousel .control-prev.control-arrow {
  &::before {
    content: url("../../resources/icon/Dashboard/CarouselArrowLeft.svg");
    border-right: 0;
    width: 28.5px;
    height: 30px;
    position: relative;
    left: -11px;
    opacity: 9.5;
  }
}

.card .carousel .slide .legend {
  bottom: 0;
  left: 0;
  margin-left: 0;
  width: 100%;
  border-radius: 0;
  background: rgba(21, 23, 60, 0.5);
  padding: 5px 10px 16px 5px;
  opacity: 1;
  border-top: 1px solid rgba(38, 38, 74, 0.404);
}

.slader-item-arrows .carousel .control-next.control-arrow,
.card .carousel .control-next.control-arrow {
  &::before {
    content: url("../../resources/icon/Dashboard/CarouselArrowRight.svg");
    border-left: 0;
    width: 28.5px;
    height: 30px;
    position: relative;
    left: 9px;
    opacity: 9.5;
  }
}

.card .card__carousel-img:hover {
  &::after {
    content: url("../../resources/icon/Card/HoverCard.svg");
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.card {
  display: flex;
  justify-content: center;
  height: 460px;

  &__carousel {
    max-width: 255px;
    height: 190px;
  }

  &__carousel-item {
    position: relative;
    width: 100%;
    padding-bottom: 70%;
    overflow: hidden;
  }

  &__carousel-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    > img {
      display: none;
    }
  }
  &__content {
    position: relative;
    margin-top: 0;
    height: 280px;
    border-top: 0;
  }
  &__buttons {
    position: relative;
    top: -36px;
    margin-bottom: -36px;
  }
  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__button {
  }
  &__devider {
    width: 100%;
    border: 0.5px solid #323054;
    margin-top: 17.5px;
  }
  &__discription {
    margin: 13px 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8186af;
  }
}

.red {
  margin-top: 5px;
  color: #fa544d;
}

@media (max-width: 768px) {
  .card {
    &__carousel {
      max-width: 350px;
      height: 100%;
    }
  }
}
