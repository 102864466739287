.skeleton {
  &__icon {
    background: #1e2044;
    border: 1px solid #323054;
    border-radius: 3px;
    height: 19px;
    width: 19px;
    animation: loading 1s ease infinite alternate;
  }
  &__title {
    margin: 5px 0 0 10px;
    height: 15px;
    width: 153px;
    border-radius: 20px;
    background: rgba(129, 134, 175, 0.4);
    animation: loading 1s ease infinite alternate;
  }
}

@keyframes loading {
  to {
    opacity: 0.5;
  }
}

.skeleton-main-news {
  margin-top: 35px;

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #323054;
    background: #1e2044;
    width: 100%;
    height: 190px;
    margin-bottom: 10px;
    animation: loading 1s ease infinite alternate;
  }
}

.sk {
  border-radius: 20px;
  background: #1e2044;
  animation: loading 1s ease infinite alternate;
}

.skeleton-card-news {
  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    width: 78px;
    height: 78px;
    border: 1px solid #323054;
    background: #1e2044;
    animation: loading 1s ease infinite alternate;
    margin-right: 10px;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
  }
}

.skeleton-info-news {
  width: 100%;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__views {
    display: flex;
    align-items: center;
  }

  &__data {
    height: 9px;
    width: 56px;
  }

  &__divider-y {
    background: #323054;
    width: 1px;
    height: 15px;
    margin-left: 5px;
  }

  &__view {
    margin-left: 5px;
    height: 9px;
    width: 42px;
  }

  &__hashtag {
    height: 10px;
    width: 46px;
    border: 1px solid #fa544d;
    border-radius: 20px;
    padding: 0 3px;
  }

  &__divider {
    width: 100%;
    height: 1px;
    background: #323054;
    margin-top: 7.5px;
  }

  &__description {
    height: 11px;
    width: 100%;
    margin-top: 10px;
  }

  &__description-short {
    margin-top: 10px;
    width: 70%;
    height: 11px;
  }
}

.comp-news-btn {
  display: flex;
  justify-content: center;
  margin: 37px 0 17px 0;
  &__center {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 340px;
    height: 38px;
    border: 1px solid #fa544d;
    border-radius: 10px;
    animation: loading 1s ease infinite alternate;

    div {
      height: 11px;
      width: 120px;
    }
  }
}

.skeleton-project-news {
  width: 100%;
  border: none;
  padding: 10px 0 18px 52px;
  background: #191b3f;
  border-bottom: 1px solid #323054;
  &__container {
    height: 485px;
    overflow: scroll;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }
  &__item {
    display: block;
    padding: 10px;
    margin-top: 17px;
    padding-bottom: 0;
  }
  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    width: 68px;
    height: 61px;
    border-radius: 3px;
    border: 1px solid #323054;
    background: #1e2044;
    animation: loading 1s ease infinite alternate;
    margin-right: 10px;
  }
}

.dlendor-eports-sk {
  width: 68px;
  height: 61px;
  border-radius: 3px;
  border: 1px solid #323054;
  background: #1e2044;
  animation: loading 1s ease infinite alternate;
  margin-right: 10px;
  width: 38px;
  height: 38px;
}
