.rating-wrapper {
  display: flex;
  position: relative;
  top: -6px;
}

.circle-number {
  padding-top: 10px;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #fa544d;
  border: 1px solid #323054;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  position: relative;
  z-index: 1;
}

.rating {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 10px;
  width: 110px;
  height: 28px;
  border: 1px solid #323054;
  border-left: 0;
  border-right: 0;
  background: #080a31;
}

.css-d0uhtl {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border: 1px solid #323054;
  background: #080a31;
  border-radius: 0 100% 100% 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin: 0 !important;
  padding-right: 2px;
  color: #06be30;
}

.rating-box {
  display: flex;
  position: relative;
  top: 5px;
  right: 10px;
  z-index: 0;
}

.css-dqr9h-MuiRating-label {
  color: #fa544d;
  opacity: 7;
}

.rating-box .MuiSvgIcon-root,
.rating-box .MuiSvgIcon-fontSizeInherit,
.rating-box .css-1vooibu-MuiSvgIcon-root {
  font-size: 1rem !important;
  color: #fa544d;
}
