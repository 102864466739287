.investors-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  &__item {
    display: flex;
    background: #15173c;
    border: 1px solid #323054;
    height: 18px;
    width: 100px;
    border-radius: 20px;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 18px;
    border-right: 1px solid #323054;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 4px 8px 4px 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #43fcff;
  }
}
.investor-chart {
  max-height: 125px;
}
