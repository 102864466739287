.amount-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.amount-fixed,
.amount-center {
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 19px;
  border-radius: 5px;
  border: 1px solid #323054;
  background: #1e2044;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #8186af;
}

.amount-center {
  padding: 0 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #fa544d;
}

.slider-counter {
  margin-top: 30px;
}

.css-exkjcx,                            // css-7drnjp
.css-187mznn-MuiSlider-root {
  color: #fa544d !important;
}

.css-7drnjp,
.css-eg0mwd-MuiSlider-thumb {
  border: 1px solid #ffa8b2;
}

.css-b04pc9,
.MuiSlider-rail {
  background: #8186af !important;
}

.input-form {
  margin-top: 24px;
}

.input-name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8186af;
  margin: 0 0 2px 10px;
}

.input-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  min-width: 30px;
  border: 1px solid #323054;
  background: #15173c;
  border-radius: 5px 0 0 5px;
}

.input-calculator {
  width: 100%;
  flex-grow: 1;
  height: 30px;
  border: 1px solid #323054;
  border-left: none;
  background: #15173c;
  border-radius: 0 5px 5px 0;
  outline: none;
  padding: 6px 10px 3px 10px;
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  &::placeholder {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
  }
}

.input-label {
  display: flex;
  margin-top: 5px;
}

.project-milestones-number {
  min-width: 30px;
  height: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #fa544d;
}

.project-milestones-check {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  min-width: 31px;
  border: 1px solid #323054;
  background: #15173c;
  border-radius: 5px 0 0 5px;
}

.project-milestones-text {
  display: inline-block;
  height: 30px;
  width: 100%;
  border: 1px solid #323054;
  border-left: none;
  background: #1e2044;
  border-radius: 0 5px 5px 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 7px 10px 8px 10px;
}

.project-milestones-info,
.project-milestones,
.project-milestones-disabled {
  display: flex;
}

.project-milestones-info {
  width: 100%;
}

.project-milestones-number-disabled {
  min-width: 30px;
  height: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #3d3f5e;
}

.project-milestones-text-disabled {
  display: inline-block;
  height: 30px;
  width: 100%;
  border: 1px solid #323054;
  border-left: none;
  background: #1e2044;
  border-radius: 0 5px 5px 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 7px 10px 8px 10px;
  color: #3d3f5e;
}

.-disabled * {
  fill: #3d3f5e;
}

.project-milestones-line {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.padding-box {
  min-height: 100%;
  padding: 30px 30px;
}
