.key-performance {
  &__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #323054;
    padding-bottom: 13px;
    margin-bottom: 12px;
    &:last-child {
      border-bottom: 0;
      margin: 0;
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px;
    width: 37px;
    border-radius: 10%;
    border: 1px solid #323054;
  }

  &__text {
    margin-top: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #8186af;
  }
  &__amount {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__top {
    padding-top: 7px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #43fcff;
  }

  &__bottom {
    margin-top: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #8186af;
  }
  &__amount-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    div {
      padding: 0;
    }
  }
}
