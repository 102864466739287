.conditions-p-presentation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.condition-p-presentation {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  align-items: center;
}

.conditions-name-p-presentation-left {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8186af;
}

.conditions-name-p-presentation-left:after {
  content: "*";
  color: #fa544d;
}

.conditions-sum-p-presentation-left {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100px;
  background: #1e2044;
  border-radius: 5px;
  border: 1px solid #323054;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  color: #fa544d;
}

.conditions-name-p-presentation-rigth {
  margin-left: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8186af;
}

.conditions-sum-p-presentation-rigth {
  text-align: start;
  padding: 7px 10px 8px 10px;
  outline: none;
  margin-top: 5px;
  height: 30px;
  width: 177px;
  background: #1e2044;
  border-radius: 5px;
  border: 1px solid #323054;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
}
.conditions-sum-p-presentation-rigth::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
}

.table-container {
  margin-top: 35px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 10px;
  overflow-x: auto;
}

.table-head {
  padding: 0 10px;
  margin-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #8186af;
}

.table-row {
  height: 48px;
  background: #1e2044;
  border-top: 1px solid #323054;
  border-bottom: 1px solid #323054;
  padding: 12px;
}

.border-first {
  border-radius: 5px 0 0 5px;
  border-left: 1px solid #323054;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.border-last {
  border-radius: 0 5px 5px 0;
  border-right: 1px solid #323054;
}

.column-sec-third {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8186af;
}

.column-for-fifth {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #43fcff;
}

.bg-item {
  position: relative;
  height: 28px;
  width: 126px;
  top: -22px;
  left: -18px;
  background: rgba(67, 252, 255, 0.05);
  border-radius: 5px;
}

.table-head-mg {
  padding: 0;
}

.width-50 {
  width: 60px;
}
