.title-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.search-filter-table {
  display: flex;
  background: #15173c;
  border: 1px solid #323054;
  height: 28px;
  width: 300px;
  border-radius: 20px;
  margin-top: 30px;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 27px;
    border-right: 1px solid #323054;
    cursor: pointer;
  }
  &__input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    input {
      width: 86%;
      border: 0px;
      background: none;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #8186af;
      outline: #323054;
      padding: 4px 0 3px 5px;
      &::placeholder {
        text-align: left;
        color: #8186af;
        font-family: "Montserrat";
      }
    }
  }
}
