.info-title {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    &:first-child {
      &::before {
        content: "";
        position: relative;
        left: 4px;
        width: 7px;
        height: 7px;
        border-radius: 50px;
        background: #fff;
        border: 2px solid #43fcff;
      }
    }
    &:nth-child(2) {
      &::before {
        content: "";
        position: relative;
        left: 4px;
        width: 7px;
        height: 7px;
        border-radius: 50px;
        background: #fff;
        border: 2px solid #fe5461;
      }
    }
  }
  &__name {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8186af;
  }
  &__amoutn {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
}

.chart-investment-and-accruals {
  margin-top: 25px;
  height: 190px;
}

@media (max-width: 425px) {
  .info-title {
    display: flex;
    align-items: normal;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 5px !important;
  }
  .chart-investment-and-accruals {
    margin-top: 15px;
  }
}
