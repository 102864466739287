.wrapper-options {
  height: 48px;
  width: 250px;
  background: #15173c;
  border: 1px solid #242648;
  border-radius: 5px;
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    border-right: 1px solid #242648;
  }
}

.selected-option-castom__tansaction {
  padding: 10px;
}

.options-castom__tansaction {
  top: 47px;
}

.option-castom__tansaction {
  height: 46px;
  align-items: center;
}

.arrow__tansaction {
  right: 8px;
}

.download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8186af;
  text-decoration: none;
  cursor: pointer;
}

.operation-icon {
  justify-content: center;
  background: #090b32;
  height: 28px;
  width: 28px;
  border-radius: 4px;
  filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.102));
}

.boxs-chart .Mui-disabled,
.boxs-chart .MuiPaginationItem-previousNext,
.boxs-chart
  .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-outlined.MuiPaginationItem-circular.MuiPaginationItem-page {
  border-radius: 5px;
}

.grid-table-trans {
  display: grid;
  grid-template-columns: 100px 160px 140px 140px;
  grid-column-gap: 160px;
}

.grid-table-trans-title {
  display: grid;
  grid-template-columns: 100px 144px 116px 140px;
  grid-column-gap: 180px;
  margin-top: 30px;
  margin-bottom: 17px;
}

.date-input-range {
  padding: 6px 0 7px 10px;
  &__name {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8186af;
  }
  &__input {
    margin-top: 2px;
    .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      width: 70px !important;
    }
    display: flex;
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
      width: 100%;
      input {
        padding: 0;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #fff;
        opacity: 1;
        text-align: center;
      }
      input::placeholder {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #fff;
        opacity: 1;
      }
    }
    span {
      position: relative;
      top: -4px;
    }
  }
  .MuiStack-root {
    margin: 0 !important;
  }
}

.skeleton-investment {
  display: grid;
  grid-template-columns: 100px 160px 140px 140px;
  grid-column-gap: 160px;
}
