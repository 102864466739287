.billing-info-title {
  align-items: center;
  &__divider {
    width: 100%;
    height: 1px;
    background: #323054;
  }
  &__title {
    width: 80%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8186af;
    text-align: center;
  }
}
