.next-modal {
  margin-top: 15px;
  &__input {
    border-radius: 0 !important;
    margin-bottom: 20px;
  }
  &__bt-input {
    border-radius: 0 5px 5px 0;
    border: 1px solid #323054;
    border-left: 0;
    background: #1e2044;
    padding: 1px 0 2px 0;
    width: 90px;
    height: 30px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #fa544d;
    text-align: center;
    cursor: pointer;
    &:hover {
      background: #fa544d;
      color: #fff;
    }
  }
  &__bt {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0 1px 0 0;
    width: 90px;
    height: 16px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-right: 10px;
  }
  a {
    text-decoration: none;
  }
}

.main-checkbox-container {
  padding: 19px 42px 0 12px;
  &__description {
    padding: 2px 0 0 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #a9b9c6;
  }
  &__bt {
    margin-top: 30px;
  }
}

.disable-bt {
  background: #3d3f5e !important;
  border: 0;
  &:hover {
    border: 0;
  }
}

.checkbox-container {
  display: flex;
  min-width: 100%;
  margin-top: 9px;
}

.checkbox-container input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.checkbox-container .custom-checkbox {
  display: inline-block;
  height: 14px;
  width: 14px;
  border: 1px solid #323054;
  border-radius: 3px;
  cursor: pointer;
}

.checkbox-container .custom-checkbox::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #1e2044;
  border-radius: 2px;
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
}

.checkbox-container .custom-checkbox::after {
  content: "";
  display: block;
  position: relative;
  top: -16px;
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid #fa544d;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.checkbox-container input[type="checkbox"]:checked + .custom-checkbox::before {
  transform: scale(1);
}

.checkbox-container input[type="checkbox"]:checked + .custom-checkbox::after {
  opacity: 1;
}
