.content {
  padding: 20px;
  overflow: hidden;
  width: 100%;
}

.content-special {
  overflow: hidden;
}

.main-container {
  max-width: 1435px;
  padding: 0;
  display: flex;
}

@media (max-width: 1279px) {
  .content {
    padding: 85px 20px 20px 20px;
  }

  .content-special {
    margin-top: 65px;
  }
}

@media (max-width: 768px) {
  .content {
    padding: 0;
  }

  .content-special {
    margin-top: 65px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  min-height: 100%;
  min-width: 100%;
  opacity: 0.8;
  transition: opacity 2s;
}
