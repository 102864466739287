.radar-chrt {
  display: flex;
  justify-content: center;
  max-height: 320px;
  width: 100%;
}

.diversifiaction-select {
  height: 28px;
  width: 100%;
  border: 1px solid #323054;
  border-radius: 5px;
}

.custom-selects {
  position: relative;
  height: 100%;
  width: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  z-index: 10;
  cursor: default;
  img {
    align-self: start;
  }
}

.selected-option-castom {
  display: flex;
  align-items: center;
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 2px;
  cursor: pointer;
  span {
    color: #8186af;
  }
}

.options-castom {
  position: absolute;
  top: 28px;
  left: -1px;
  width: 101%;
  background-color: #090b32;
  border: 1px solid #323054;
  border-radius: 5px;
  border-top: none;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
  span {
    color: #8186af;
    padding-top: 4px;
  }
}

.option-castom {
  width: 100%;
  padding: 3px;
  cursor: pointer;
}

.option-castom:hover {
  background-color: #fa544d;
  span,
  div {
    color: #fff;
  }
}
.input-option-img {
  padding-top: 3px;
  img {
    margin: 0 10px;
    border-radius: 5px;
    width: 18px;
    height: 18px;
  }
}

.total-score {
  margin-top: 20px;
  width: 100%;
  &__text {
    text-align: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8186af;
    span {
      font-weight: 700;
      color: #43fcff;
    }
  }
}

.total-scores {
  display: flex;
  &__wrapper {
    margin-bottom: -13px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    height: 100%;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #323054;
  }
  &__wrapper:last-child {
    margin-left: 5px;
  }
  &__wrapper:first-child {
    margin-right: 5px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #323054;
    padding-top: 7.5px;
    padding-bottom: 5.5px;
    color: #8186af;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
      padding-top: 4.5px;
      padding-bottom: 3.5px;
    }
    div {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      &:last-child {
        color: #43fcff;
      }
    }
  }
}

.diversifiaction-text {
  width: 100%;
  max-height: 100%;
  margin-top: 22px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #8186af;
}

.box-special {
  padding: 22px;
  margin-top: 20px;
  margin-bottom: -65px;
}
