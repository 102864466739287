.doc-table {
  margin: 19px 0 18px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr 150px;
  grid-row-gap: 10px;
  overflow: auto;
  &__head-row {
    padding: 0 10px;
    margin-bottom: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #8186af;
    &:first-child {
      margin-left: 20px;
    }
    &:nth-child(2) {
      padding-left: 0;
    }
  }
  &__body-row {
    height: 48px;
    background: #1e2044;
    border-top: 1px solid #323054;
    border-bottom: 1px solid #323054;
    padding: 15px 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .first-child {
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #323054;
    padding-left: 30px;
  }

  .last-child {
    padding: 10px 0;
    border-radius: 0 5px 5px 0;
    border-right: 1px solid #323054;
    padding-right: 30px;
  }
  &__button {
    padding: 3.5px 5px 5.5px 5px;
  }
}
