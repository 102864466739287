.slider-dashboard .css-eg0mwd-MuiSlider-thumb,
.slider-dashboard .css-7drnjp {
  margin: 0;
  display: none !important;
}
.css-exkjcx,
.css-187mznn-MuiSlider-root {
  padding-top: 0 !important;
}

.css-b04pc9,
.css-14pt78w-MuiSlider-rail {
  background: #8186af !important;
}

.dashboard-slider {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-slider-percent {
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px solid #323054;
  background: #15173c;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #fa544d;
}

.dashboard-slider-amount {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: #fa544d;
}
.dashboard-slider-amount::after {
  content: " funded";
  color: #fff;
}
