.finance-structure {
  width: 100%;
  padding: 10px;
  margin: 70px 50px 100px 0;
  &__list {
    margin: 0 0 0 13px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  &__item-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 6px 15px 7px 15px;
    align-items: center;
    width: 80%;
  }
  &__number {
    min-width: 30px;
    height: 30px;
    margin-right: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #fa544d;
  }
  &__title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #8186af;
  }
  &__money {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
}
.doughnut {
  width: 307px;
  height: 307px;
  &__wrapper {
    position: relative;
    margin: 52px 0 0 35px;
    padding-right: 50px;
  }
}

.subtraction {
  border-radius: 130px;
  height: 230px;
  width: 230px;
  position: relative;
  z-index: -1;
  &__circle {
    position: absolute;
    left: 38px;
    top: 38px;
    border-radius: 130px;
    height: 0px;
  }
  &__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #8186af;
  }
  &__amount {
    margin-top: 11px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #fa544d;
  }
  &__wrapper {
    width: 100%;
    position: absolute;
    text-align: center;
    top: 93px;
  }
  &__point {
    padding: 8px 0 7px 0;
    width: 30px;
    height: 30px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #fa544d;
  }
  &__line-one {
    height: 54px;
    width: 0;
    border: 1px solid #323054;
    position: absolute;
    left: 14px;
    bottom: 29px;
  }
  &__wrapper-point-one {
    width: 15%;
    position: relative;
    left: -3px;
    bottom: -23px;
  }
  &__line-two {
    width: 0;
    height: 51px;
    border: 1px solid #323054;
    position: absolute;
    left: 14px;
  }
  &__wrapper-point-two {
    width: 15%;
    position: relative;
    left: -19px;
    bottom: 303px;
  }
  &__line-three {
    height: 40px;
    width: 0;
    border: 1px solid #323054;
    position: absolute;
    left: 14px;
    bottom: -39px;
  }
  &__wrapper-point-three {
    width: 15%;
    position: relative;
    left: 60px;
    bottom: 378px;
  }
}

.doughnut-circle {
  position: absolute;
  top: 44px;
  left: 44px;
  width: 220px;
  height: 220px;
  border: 44px solid #fff;
  opacity: 0.2;
  border-radius: 150px;
  &__border-radius {
    position: absolute;
    top: 44px;
    left: 44px;
    width: 220px;
    height: 220px;
    border: 1px solid #fff;
    opacity: 0.5;
    border-radius: 150px;
  }
  &__border-radius-into {
    position: absolute;
    top: 86px;
    left: 86px;
    width: 136px;
    height: 136px;
    border: 1px solid #fff;
    opacity: 0.4;
    border-radius: 150px;
  }
}
