.product-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 30px 0;
}

@media (max-width: 500px) {
  .product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0 30px 0;
  }
}

.css-1v2lvtn-MuiPaginationItem-root,
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root,
.css-1xr9krm {
  color: #fff !important;
}

.MuiButtonBase-root.Mui-disabled.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-circular.Mui-disabled.MuiPaginationItem-previousNext.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root,
.css-lrb33l {
  min-height: 24px !important;
  width: 24px !important;
  background: #1e2044 !important;
  border: 1px solid #323054 !important;
  border-radius: 100% !important;
}

.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-circular.MuiPaginationItem-previousNext.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
  min-height: 24px !important;
  width: 24px !important;
  background: #1e2044 !important;
  border: 1px solid #323054 !important;
}
