.comparison {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  &__wrapper {
    max-width: 270px;
    margin-left: 10px;
  }
  .ml-0 {
    margin-left: 0 !important;
  }
  &__title-component {
    display: flex;
    align-items: center;
    height: 194px;
    font-family: "Kanit";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #8186af;
  }
  &__box-wrapper {
    display: flex;
    align-items: center;
    padding: 0 20px;
    min-height: 58px;
    width: 100%;
    min-width: 220px;
    margin-top: 10px;
    background: #1e2044;
    border: 1px solid #323054;
    border-radius: 3px;
  }
  &__box-icon {
    position: relative;
    top: 2px;
  }
  &__box-title {
    margin-left: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #8186af;
  }

  .comparison-item {
    &__img {
      img {
        height: 190px;
        max-width: 100%;
        object-fit: cover;
      }
    }
    &__box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 5px;
      min-height: 58px;
      width: 100%;
      min-width: 220px;
      margin-top: 10px;
      background: #1e2044;
      border: 1px solid #323054;
      border-radius: 3px;
      &-input {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        padding: 0 35px;
        min-height: 58px;
        width: 100%;
        min-width: 220px;
        background: #1e2044;
        border: 1px solid #323054;
        border-radius: 3px;
      }
    }
    &__input-icon {
      color: #fa544d;
    }
    &__input-calculator {
      padding: 4px 0 4px 15px;
    }
    &__title,
    &__profit {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
    &__funding-state {
      max-height: 55px;
      width: 100%;
    }
    &__profit {
      color: #43fcff;
    }
    &__btn {
      margin: 30px 0;
      &.comparison-disabled {
        border: 1px solid #3d3f5e;
      }
    }
    .comparison-disabled {
      color: #3d3f5e;

      &::placeholder {
        color: #3d3f5e;
      }
      &:hover {
        background: none;
      }
    }
  }
}

.comparison > * {
  flex: 1 1;
}
