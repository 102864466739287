.company-news-item {
  &__img {
    img {
      width: 100%;
      max-height: 396px;
    }
  }
}

.info-news {
  &__full-description {
    margin: 12px 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #8186af;
  }
}

.main-news-item {
  text-decoration: none;
  margin-top: 30px;
}

@media (max-width: 1024px) {
  .project-news {
    &__menu {
      max-height: 550px;
    }
  }
}
