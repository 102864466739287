.toggle-button {
  position: relative;
  display: none;
  z-index: 10;
}

.top-menu {
  display: none;
}

.logo {
  display: inline-block;
  position: relative;
  z-index: 5;
  padding: 14px 0 0 29px;
}

.z-index-chenge {
  z-index: 960 !important;
  transition: transform 500ms ease-out;
}

@media (max-width: 1279px) {
  .top-menu {
    display: block;
    position: fixed;
    height: 65px;
    width: 100%;
    left: 0px;
    top: 0px;
    background: #15173c;
    z-index: 950;
    transition: transform 500ms ease-out;
  }

  .top-menu-toggle-button {
    display: block;
    height: 38px;
    width: 38px;
    background: #1e2044;
    border-radius: 100px;
    border: 1px solid #323054;
    padding: 11px;
    right: 25px;
    top: 14px;
    position: absolute;
    display: block;
    cursor: pointer;
    filter: drop-shadow(20px 0px 20px rgba(0, 0, 0, 0.102));
  }

  .toggle-button {
    height: 38px;
    width: 38px;
    background: #1e2044;
    border-radius: 100px;
    border: 1px solid #323054;
    padding: 11px;
    right: 15px;
    top: 14px;
    position: absolute;
    display: block;
    z-index: 15;
    filter: drop-shadow(20px 0px 20px rgba(0, 0, 0, 0.102));
  }
}

.button-return {
  margin-bottom: 65px;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}

.button-return-rectangle {
  text-decoration: none;
  height: 36px;
  width: 235px;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #fa544d;
}

.button-return-circle {
  height: 36px;
  width: 36px;
}

.button-return-circle-empty {
  height: 36px;
  width: 36px;
}

.button-return-circle:hover,
.button-return-rectangle:hover {
  background: #fa544d;
  border: 1px solid #ffa8b2;
  color: #fff;
}

@media (max-width: 768px) {
  .sidebar-wrapper {
    min-width: 0;
    transition: transform 500ms ease-out;
  }
  .button-return {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
  }
}
