.sidebar-inside {
  display: block;
  width: 285px;
  background-color: #15173c;
  filter: drop-shadow(20px 0px 20px rgba(0, 0, 0, 0.102));
  border: solid 1px #26264a;
  transition: transform 500ms ease-out;
  transform: translateX(0);
  min-width: 285px;
  overflow: auto;
  min-height: 100vh;
  max-height: 100vh;
}

.title {
  margin: 21px 79px 0 45px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  background: #13153a;
}

.title::before {
  content: url(../../../resources/icon/SidebarInside/ProjectDetails.svg);
  margin-right: 20px;
  position: relative;
  top: 5px;
}

.devider-title {
  margin-top: 23px;
  border-bottom: 1px solid #323054;
}

.nav-link {
  margin: 9px 90px 9px 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #8186af;
}

.arrow-sidebar {
  position: relative;
  margin: 0 9px 0 18px;
  top: 2px;
}

.arrow {
  margin: 0 9px 0 18px;
  position: relative;
  top: 2px;
}

.devider-link {
  display: flex;
  justify-content: flex-end;
  div {
    border: 1px solid #323054;
    width: 255px;
  }
}

.container-list {
  padding: 11px 17.5px 96px 18px;
}

.position {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 23px;
  width: 38px;
  height: 38px;
  cursor: pointer;
}

.hover {
  background: #fa544d;
  cursor: pointer;
  border: 1px solid #ffa8b2;
  border-radius: 5px;
  filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.102));
}

.hover-svg * {
  fill: #fff;
}

.item {
  cursor: pointer;
  display: flex;
  align-items: center;
  list-style-type: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8186af;
  margin: 4px 0;
  width: 205px;
}

.item-hover {
  font-family: "Inter";
  font-weight: 700;
  color: #fa544d !important;
}

@media (max-width: 1279px) {
  .title {
    margin-top: 65px;
  }
}

.b-menu {
  display: none;
  position: fixed;
  height: 64px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  padding: 13px 25px;
  background: #15173c;
  z-index: 400;
  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    &::before {
      content: url(../../../resources/icon/SidebarInside/ProjectDetails.svg);
      margin-right: 14px;
      position: relative;
      top: 5px;
    }
  }
  &__button {
    height: 38px;
    min-width: 38px;
  }
}

.list-button {
  max-height: 100vh;
  overflow: scroll;
  display: block;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  z-index: 400;
  transition: transform 500ms ease-out;

  &__item {
    height: 45px;
    width: 207px;
  }
  &__wrapper {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 10px 8px 22px;
    border: 1px solid #323054;
    background: #15173c;
  }
  &__item {
    display: flex;
    align-items: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8186af;
    margin-left: 7px;
  }

  .circle-bg {
    min-width: 35px;
    height: 35px;
  }

  &__active {
    background: #fa544d;
    border: 1px solid #ffa8b2;
  }
}

@media (max-width: 768px) {
  .content {
    margin: 65px 20px 75px 20px;
  }

  .b-menu {
    display: block;
  }

  .sidebar-inside {
    display: block;
    position: absolute;
    transform: translateX(-100%);
    transition: transform 500ms ease-out;
    width: 285px;
    background-color: #15173c;
    filter: drop-shadow(20px 0px 20px rgba(0, 0, 0, 0.102));
    border: solid 1px #26264a;
    min-width: 285px;
  }

  .list-button {
    padding-bottom: 59px;
    &__hidden {
      transition: transform 500ms ease-out;
      transform: translateY(0);
    }
  }
}
