.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  background-color: #090b32;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8186af !important;
  max-height: 28px;
  padding: 5px 0 4px 15px;
  border: 1px solid #323054;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
  padding: 0;
  border: 1px solid #323054;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.404));
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
  background: #090b32;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8186af;
  height: 28px;
  padding: 6px 0 7px 15px;
  &:hover {
    background: #fa544d !important;
    color: #fff;
  }
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected {
  background: #fa544d !important;
  color: #fff;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
  color: #fff;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiPaginationItem-icon {
  color: #fa544d !important;
}
