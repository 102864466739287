.sidebar-wrapper {
  position: relative;
  z-index: 900;
  min-width: 570px;
  transition: transform 500ms ease-out;

  &__fixed {
    position: fixed;
    // display: flex;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 285px;
  transition: transform 500ms ease-out;
  background-color: #15173c;
  filter: drop-shadow(20px 0px 20px rgba(0, 0, 0, 0.102));
  border: solid 1px #26264a;
  z-index: 1000;
  overflow: auto;
  min-height: 100vh;
  max-height: 100vh;
}

.filter-none {
  filter: none;
}

@media (max-width: 1279px) {
  .display-none {
    display: none;
  }

  .sidebar-wrapper {
    min-width: 285px;
    transition: transform 500ms ease-out;
  }

  .hidden-width-285 {
    width: 285px;
  }

  .hidden-width {
    width: 0;
  }

  .hidden {
    min-width: 0px !important;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    overflow: scroll;
    transition: transform 500ms ease-out;
    transform: translateX(-100%);
    z-index: 1000;

    &.open {
      transform: translateX(0);
    }
  }
}

.user-panel {
  margin: 30px 0 17px 35px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #8186af;
}

.sidebar-list {
  width: 100%;
  height: auto;
  padding: 0;
}

.sidebar-list .sidebar-row {
  width: 235px;
  height: 38px;
  list-style-type: none;
  color: #8186af;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.sidebar-row #icon {
  margin-left: 25px;
  flex: 10%;
}

.sidebar-row #title {
  flex: 90%;
  margin-left: 22px;
}

.sidebar-list .sidebar-row:hover {
  cursor: pointer;
  height: 38px;
  filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.149));
  background: #fa544d;
  border: 1px solid #ffa8b2;
  border-radius: 5px;
  color: #fff;
}

.sidebar-list #active {
  height: 38px;
  filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.149));
  background: #fa544d;
  border: 1px solid #ffa8b2;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
}
