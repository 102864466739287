@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;300;400;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  /* Common styles for scrolling */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(129, 134, 175, 0.507);
    border-radius: 8px;
  }

  /* Chairs for corner square */
  ::-webkit-scrollbar-corner {
    background-color: rgba(129, 134, 175, 0.507);
    border: 3px solid rgba(129, 134, 175, 0.507);
  }

  /* Styles for mobile version */
  @media (max-width: 767px) {
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(129, 134, 175, 0.507);
      border-radius: 4px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }
  /* Styles for Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(129, 134, 175, 0.507) transparent;

  @-moz-document url-prefix() {
    ::-moz-scrollbar {
      width: 5px;
    }

    ::-moz-scrollbar-thumb {
      background-color: rgba(129, 134, 175, 0.507);
      border-radius: 8px;
    }
  }
}

body {
  background-color: #090b32;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="text"] {
  font-family: inherit; /* 1 */
  font-size: inherit; /* 1 */
  line-height: inherit; /* 1 */
  margin: 0; /* 2 */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.icon {
  fill: #ffffff;
}

.button-general {
  cursor: pointer;
  padding: 9.5px 10px;
  background: transparent;
  filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.102));
  border: 1px solid #fa544d;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  width: 100%;
  text-decoration: none;
}
.button-general:hover {
  background: #fa544d;
  border: 1px solid #ffa8b2;
}

.h2-title {
  margin-top: 22px;
  font-family: "Kanit";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #8186af;
  &__discription {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #8186af;
  }
}

.box {
  margin-top: 18px;
  padding: 20px;
  background-color: #13153a;
  border: 1px solid #323054;
  width: 100%;
  filter: drop-shadow(20px 0px 20px rgba(0, 0, 0, 0.102));
}

.box-p {
  background-color: #13153a;
  border: 1px solid #323054;
  width: 100%;
  filter: drop-shadow(20px 0px 20px rgba(0, 0, 0, 0.102));
}

.box-col {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.css-eg0mwd-MuiSlider-thumb {
  display: none;
}

.circle-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 28px;
  height: 28px;
  width: 28px;
  background: #1e2044;
  border: 1px solid #323054;
  border-radius: 100px;
}

.rectangle-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  background: #1e2044;
  border: 1px solid #323054;
  border-radius: 5px;
}

.d-flex {
  display: flex;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gm-control-active {
  background: #15173c !important;
  border: 1px solid #323054 !important;
  color: #fa544d !important ;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.gm-control-active div {
  background: #15173c !important;
}

.gm-style-iw,
.gm-style-iw-c,
.gm-style-iw-d {
  background: #15173c !important;
  overflow: hidden !important;
}

.gm-style-iw-c {
  top: -55px !important;
}

.gm-style-iw-tc::after {
  background: none !important;
}

.gm-style-mtc button {
  background: none padding-box #15173c !important;
  display: table-cell;
  border: 1px solid #323054 !important;
  height: 40px;
  vertical-align: middle;
  color: rgb(102, 102, 102) !important ;
  min-width: 68px;
}
