.boxs-chart {
  height: 230px;
  width: 100%;
  background: #13153a;
  border: 1px solid #242648;
  border-radius: 4px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  &__title {
    padding: 10px 0 6px 0;
    border-bottom: 1px solid #242648;
    h4 {
      text-align: center;
      font-family: "Kanit";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #8186af;
    }
  }
  &__container {
    padding: 15px;
  }
}

.panel-info {
  display: flex;
  width: 100%;
  height: 17px;
  border: 1px solid #242648;
  border-radius: 3px;
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 18px;
    max-height: 18px;
    border-right: 1px solid #242648;
  }
  &__title {
    padding: 4px 9px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #43fcff;
  }
}

.panel-info__wrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 14px;
}
