.m-0 {
  margin: 0;
  padding: 5px 7px 9px 10px;
}

.info-descr {
  height: 100%;
  margin: 5px 0 -10px 0 !important;
  padding: 9px 35px 19px 15px;
}

.info-title {
  margin-top: 22px !important;
}

.custom-select {
  min-width: 161.5px !important;
  &__code {
    width: 50px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8186af;
  }
  &__option-default::before {
    content: "country";
    color: #8186af;
  }
}

.inp-error {
  border: 1px solid #fa544d !important;
}
.inp-success {
  border: 1px solid #43fcff !important;
}

@media (max-width: 500px) {
  .custom-select {
    min-width: 244px !important;
  }
}
