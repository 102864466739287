.comp-news {
  display: flex;

  &__main-content {
    padding: 20px;
    // max-width: 730px;
    max-width: 1030px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin: 37px 0 17px 0;
    text-decoration: none;

    button {
      max-width: 340px;
    }
  }
  &__left-panel {
    min-width: 380px;
    background: #13153a;
    border: 1px solid #323054;
    min-height: 100vh;
    overflow: auto;
  }
  &__box-title {
    display: flex;
    margin-top: 10px;
  }
  &__title {
    margin-left: 10px;
    font-family: "Kanit";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #8186af;
  }
  &__icon {
    position: relative;
    top: 3px;
  }
}

.main-news {
  text-decoration: none;
  margin-top: 30px;
  &__item {
    display: block;
    text-decoration: none;
    width: 100%;
    img {
      object-fit: cover;
      width: 100%;
      max-height: 190px;
    }
    button {
      position: absolute;
      text-decoration: none;
      top: 10px;
      left: 20px;
    }
  }
  &__info-wrapper {
    padding: 10px;
  }
  &__but-b-menu {
    display: none;
  }
  &__style-center-btn {
    padding: 0 10px;
    height: 100%;
    width: fit-content;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 15px;
    color: #8186af;
    transition: opacity 1s ease;
  }
}

.card-news {
  text-decoration: none;
  &__item {
    display: flex;
    align-items: center;
    padding: 10px;
    text-decoration: none;

    img {
      object-fit: cover;
      width: 78px;
      height: 78px;
      margin-right: 10px;
    }
  }
}

.info-news {
  width: 100%;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__views {
    display: flex;
    align-items: center;
  }

  &__data {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #8186af;
  }

  &__divider-y {
    background: #323054;
    width: 1px;
    height: 15px;
    margin-left: 5px;
  }

  &__view {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }

  &__icon {
    position: relative;
    top: -2px;
  }

  &__amount {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #8186af;
    margin-left: 5px;
  }

  &__hashtag {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #43fcff;
    border: 1px solid #fa544d;
    border-radius: 20px;
    padding: 0 3px;
  }

  &__divider {
    width: 100%;
    height: 1px;
    background: #323054;
    margin-top: 7.5px;
  }

  &__description {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #8186af;
    padding-right: 55px;
    margin-top: 10px;
  }
}

.project-news {
  width: 100%;
  border: none;
  padding: 10px 0 18px 52px;
  background: #191b3f;
  border-bottom: 1px solid #323054;
  &__container {
    overflow: scroll;
  }
  &__height {
    height: 485px;
  }
  &__wrapper {
    display: flex;
    width: 100%;
  }
  &__item {
    display: block;
    padding: 10px;
    margin-top: 17px;
    padding-bottom: 0;
    img {
      width: 68px;
      height: 61px;
      border-radius: 3px;
    }
  }
}

.mt-0 {
  margin-top: 0;
}

.br-t {
  border-top: 1px solid #323054;
}

.dlendor-eports {
  margin-right: 10px;
  img {
    width: 38px;
    height: 38px;
  }
}

.ht {
  height: 320px;
}

@media (max-width: 1024px) {
  .comp-news {
    &__left-panel {
      position: fixed;
      min-width: 300px;
      background: #13153a;
      border: 1px solid #323054;
      min-height: 100vh;
      overflow: auto;
      z-index: 10;
      right: -510px;
      transition: right 1s ease;
    }
  }
  .close-menu {
    right: 0px;
  }
  .content-special {
    margin-bottom: 65px;
  }

  .main-news {
    &__b-menu {
      display: flex;
      position: fixed;
      height: 64px;
      width: 100%;
      left: 0px;
      bottom: -1px;
      padding: 13px 25px;
      background: #15173c;
      z-index: 20;
    }
    &__but-b-menu {
      display: block;
    }
  }
  .ht {
    height: 450px;
  }
}

.nidden-new {
  display: none;
}
