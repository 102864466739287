.deposit-titles-table {
  display: grid;
  grid-template-columns: 121px 164px 97px 118px 154px 100px;
  grid-column-gap: 30px;
  margin: 15px 0 27px 0;
}

.deposit-table {
  display: grid;
  grid-template-columns: 95px 150px 95px 100px 160px 240px;
  grid-column-gap: 36px;
  align-items: center;
  height: 48px;
  width: 1065px;
  border: 1px solid #323054;
  background: #1e2044;
  border-radius: 5px;
  margin-top: 10px;
  padding: 7px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8186af;

  &__UserName {
    display: flex;
    align-items: center;
    &-img {
      display: flex;
      align-items: center;
    }
    &-title {
      margin-left: 10px;
    }
  }

  &__Action {
    display: flex;
    align-items: center;
    &-title {
      margin-left: 5px;
    }
  }

  &__Amount {
    display: flex;
    align-items: center;
    &-title {
      margin-left: 10px;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }

  &__Investment {
    display: flex;
    justify-self: center;
    align-items: center;
    &-title {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      div:first-child {
        color: #43fcff;
      }
      div:last-child {
        font-size: 9px;
        line-height: 11px;
        margin-top: 4px;
      }
    }
    &-icon {
      margin-top: 4px;
    }
  }

  &__TransactionLink {
    display: flex;
    align-items: center;
    &-title {
      margin: 0 10px 0 6px;
      color: "#fa544d";
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }
}

@keyframes loading {
  to {
    opacity: 0.5;
  }
}

.s-deposit-table {
  display: grid;
  grid-template-columns: 96px 151px 98px 112px 149px 236px;
  grid-column-gap: 35px;
  align-items: center;
  height: 48px;
  width: 1065px;
  border: 1px solid #323054;
  background: #1e2044;
  border-radius: 5px;
  margin-top: 10px;
  padding: 7px 20px;
  color: #8186af;

  &__Date {
    &-icon {
      div {
        background: #323054;
        height: 14px;
        width: 14px;
        border-radius: 3px;
        animation: loading 1s ease infinite alternate;
      }
    }
  }

  &__Project {
    display: flex;
    align-items: center;
    width: 100%;
    &-img {
      background: #323054;
      min-height: 18px;
      min-width: 18px;
      border-radius: 50px;
      animation: loading 1s ease infinite alternate;
    }
    &-title {
      width: 100%;
      margin-left: 10px;
      div {
        width: 100%;
        height: 9px;
        border-radius: 10px;
        background: #323054;
        animation: loading 1s ease infinite alternate;
      }
    }
  }

  &__Status {
    display: flex;
    align-items: center;
    width: 100%;

    &-title {
      width: 100%;
      margin-left: 8px;
      div {
        width: 100%;
        height: 9px;
        border-radius: 10px;
        background: #323054;
        animation: loading 1s ease infinite alternate;
      }
    }
    &-icon {
      display: flex;
      align-items: center;
      div {
        background: #323054;
        height: 18px;
        width: 18px;
        border-radius: 50px;
        animation: loading 1s ease infinite alternate;
      }
    }
  }

  &__Distribution {
    display: flex;
    align-items: center;
    &-title {
      margin-left: 10px;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }

  &__Return {
    display: flex;
    align-items: center;
    &-title {
      margin-left: 10px;
      color: #43fcff;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }

  &__Term {
    display: flex;
    align-items: center;
    &-title {
      margin-left: 10px;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }

  &__Investment {
    display: flex;
    justify-self: center;
    align-items: center;
    width: 100%;
    &-title {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      div:last-child {
        width: 100%;
        height: 7px;
        border-radius: 10px;
        margin-top: 8px;
        background: #323054;
        animation: loading 1s ease infinite alternate;
      }
      div:first-child {
        width: 100%;
        height: 9px;
        border-radius: 10px;
        background: #323054;
        animation: loading 1s ease infinite alternate;
      }
    }
    &-icon {
      div {
        background: #323054;
        height: 18px;
        width: 18px;
        border-radius: 50px;
        animation: loading 1s ease infinite alternate;
      }
    }
  }

  &__Funding {
    display: flex;
    flex-direction: column;
    width: 100%;
    div:last-child {
      width: 150px;
      height: 5px;
      border-radius: 10px;
      margin-top: 8px;
      background: #323054;
      animation: loading 1s ease infinite alternate;
    }
    div:first-child {
      width: 75%;
      height: 9px;
      border-radius: 10px;
      background: #323054;
      animation: loading 1s ease infinite alternate;
    }
  }
}
