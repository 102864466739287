.accruals-filter-table {
  margin-right: 20px;
  display: flex;
  background: #15173c;
  border: 1px solid #323054;
  height: 28px;
  width: 145px;
  border-radius: 20px;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 27px;
    border-right: 1px solid #323054;
    cursor: pointer;
  }
  &__input {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      border: 0px;
      background: none;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #8186af;
      outline: #323054;
      padding: 4px 0 3px 5px;
      &::placeholder {
        color: #8186af;
        font-family: "Montserrat";
      }
    }
  }
}

.accruals-titles-table {
  display: grid;
  grid-template-columns: 113px 200px 102px 105px 134px 100px;
  grid-column-gap: 60px;
  margin: 45px 0 27px 0;
}

.accruals-table {
  display: grid;
  grid-template-columns: 95px 200px 100px 100px 140px 100px;
  grid-column-gap: 60px;
  align-items: center;
  height: 48px;
  width: 1065px;
  border: 1px solid #323054;
  background: #1e2044;
  border-radius: 5px;
  margin-top: 10px;
  padding: 7px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8186af;
  &__Date {
    display: flex;
    justify-self: center;
    align-items: center;
    &-title {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      div:last-child {
        margin-top: 4px;
      }
    }
    &-icon {
      margin-top: 4px;
    }
  }

  &__Project {
    display: flex;
    align-items: center;
    &-img {
      display: flex;
      align-items: center;
      img {
        width: 28px;
        height: 28px;
        object-fit: cover;
        border-radius: 4px;
      }
    }
    &-title {
      margin-left: 10px;
    }
  }

  &__Status {
    display: flex;
    align-items: center;
    &-title {
      margin-left: 5px;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }

  &__Distribution {
    display: flex;
    align-items: center;
    &-title {
      margin-left: 10px;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }

  &__Return {
    display: flex;
    align-items: center;
    &-title {
      margin-left: 10px;
      color: #43fcff;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }

  &__Term {
    display: flex;
    align-items: center;
    &-title {
      margin-left: 10px;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }

  &__Investment {
    display: flex;
    justify-self: center;
    align-items: center;
    &-title {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      div:first-child {
        color: #43fcff;
      }
      div:last-child {
        font-size: 9px;
        line-height: 11px;
        margin-top: 4px;
      }
    }
    &-icon {
      margin-top: 4px;
    }
  }

  &__Funding {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &-title {
      color: #43fcff;
      span {
        color: #fa544d;
      }
    }
    .range-castom {
      margin-top: 5px;
      width: 150px;
      height: 5px;
      border-radius: 20px;
      background: rgb(129 134 175 / 12%);
      overflow: hidden;
      &__track {
        width: 100%;
        height: 100%;
      }
      &__fill {
        height: 100%;
        background: #fa544d;
        transition: width 2s;
        border-radius: 5px;
      }
    }
  }
}

@keyframes loading {
  to {
    opacity: 0.5;
  }
}

.s-accruals-table {
  display: grid;
  grid-template-columns: 82px 194px 90px 91px 128px 89px;
  grid-column-gap: 70px;
  align-items: center;
  height: 48px;
  width: 1065px;
  border: 1px solid #323054;
  background: #1e2044;
  border-radius: 5px;
  margin-top: 10px;
  padding: 7px 20px;
  color: #8186af;

  &__Date {
    display: flex;
    justify-self: center;
    align-items: center;
    width: 90px;

    &-title {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      div:last-child {
        width: 75%;
        height: 9px;
        border-radius: 10px;
        margin-top: 8px;
        background: #323054;
        animation: loading 1s ease infinite alternate;
      }
      div:first-child {
        width: 100%;
        height: 9px;
        border-radius: 10px;
        background: #323054;
        animation: loading 1s ease infinite alternate;
      }
    }
    &-icon {
      div {
        background: #323054;
        height: 14px;
        width: 14px;
        border-radius: 3px;
        animation: loading 1s ease infinite alternate;
      }
    }
  }

  &__Project {
    display: flex;
    align-items: center;
    width: 100%;
    &-img {
      display: flex;
      align-items: center;
      animation: loading 1s ease infinite alternate;
    }
    &-title {
      width: 100%;
      margin-left: 10px;
      div {
        width: 100%;
        height: 9px;
        border-radius: 10px;
        background: #323054;
        animation: loading 1s ease infinite alternate;
      }
    }
  }

  &__Status {
    display: flex;
    align-items: center;
    width: 100%;

    &-title {
      width: 100%;
      margin-left: 8px;
      div {
        width: 100%;
        height: 9px;
        border-radius: 10px;
        background: #323054;
        animation: loading 1s ease infinite alternate;
      }
    }
    &-icon {
      display: flex;
      align-items: center;
      div {
        background: #323054;
        height: 18px;
        width: 18px;
        border-radius: 50px;
        animation: loading 1s ease infinite alternate;
      }
    }
  }

  &__Distribution {
    display: flex;
    align-items: center;
    &-title {
      margin-left: 10px;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }

  &__Return {
    display: flex;
    align-items: center;
    &-title {
      margin-left: 10px;
      color: #43fcff;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }

  &__Term {
    display: flex;
    align-items: center;
    &-title {
      margin-left: 10px;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }

  &__Investment {
    display: flex;
    justify-self: center;
    align-items: center;
    width: 100%;
    &-title {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      div:last-child {
        width: 100%;
        height: 7px;
        border-radius: 10px;
        margin-top: 8px;
        background: #323054;
        animation: loading 1s ease infinite alternate;
      }
      div:first-child {
        width: 100%;
        height: 9px;
        border-radius: 10px;
        background: #323054;
        animation: loading 1s ease infinite alternate;
      }
    }
    &-icon {
      div {
        background: #323054;
        height: 18px;
        width: 18px;
        border-radius: 50px;
        animation: loading 1s ease infinite alternate;
      }
    }
  }

  &__Funding {
    display: flex;
    flex-direction: column;
    width: 100%;
    div:last-child {
      width: 150px;
      height: 5px;
      border-radius: 10px;
      margin-top: 8px;
      background: #323054;
      animation: loading 1s ease infinite alternate;
    }
    div:first-child {
      width: 75%;
      height: 9px;
      border-radius: 10px;
      background: #323054;
      animation: loading 1s ease infinite alternate;
    }
  }
}
