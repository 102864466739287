.notifications-message {
  position: fixed;
  display: block;
  width: 285px;
  background-color: #15173c;
  filter: drop-shadow(20px 0px 20px rgba(0, 0, 0, 0.102));
  border: solid 1px #26264a;
  transition: transform 500ms ease-out;
  transform: translateX(100%);
  min-width: 285px;
  min-height: 100vh;
  max-height: 100vh;
  z-index: -1;

  &__hidden-content {
    transform: translateX(0%);
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    height: 65px;
    background: #191b3f;
    border-bottom: 1px solid #323054;
    padding: 0 20px 0 30px;
  }

  &__arrow {
    cursor: pointer;
  }

  &__amount-message {
    position: absolute;
    top: 22px;
    left: 76px;
    font-size: 6px;
    font-family: Inter;
  }

  &__text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #fa544d;
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 38px;
    background: #1e2044;
    border-radius: 100px;
    border: 1px solid #323054;
    filter: drop-shadow(20px 0px 20px rgba(0, 0, 0, 0.102));
    cursor: pointer;
  }

  &__hidden {
    visibility: hidden;
  }

  &__colomn-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #323054;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: #8186af;
    div:first-child {
      margin-left: 20px;
    }
    div:last-child {
      margin-left: 54px;
    }
  }
}

.message {
  &__notifications {
    width: 100%;
    height: 75vh;
    overflow: auto;
  }

  &__item {
    display: flex;
    gap: 25px;
    min-height: 94px;
    border-bottom: 1px solid #323054;
    padding: 16px 20px;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-width: 55px;
  }

  &__date {
    margin-top: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #8186af;
  }

  &__icon-n {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    border: 1px solid #323054;
  }

  &__right {
    width: 165px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8186af;
  }
}

.notifications-button {
  width: 100%;
  padding: 40px 60px;
}

@media (max-width: 1279px) {
  .notifications-message {
    transition: transform 1000ms ease-out;
    transform: translateX(0%);
    min-width: 285px;
    min-height: 100vh;
    max-height: 100vh;
    z-index: -1;

    &__hidden-content {
      transform: translateX(-100%);
    }
    &__wrapper {
      margin-top: 65px;
    }
    &__hidden {
      visibility: visible;
    }
  }
}
