.container {
  width: 1050px;
  min-height: 100%;
}

.thumb,
.button {
  display: none !important;
}

.carousel .control-dots .dot {
  background: #fa544d;
  margin: 0 3px;
}

.carousel.carousel-slider {
  overflow: visible;
}

.img {
  width: 100% !important;
  object-fit: cover;
}

.carousel-root {
  width: 100%;
  margin: 0;
}

.carousel .carousel-status {
  color: #fa544d;
  font-size: 12px;
  left: -9px;
  top: auto;
  bottom: -24px;
  padding: 0 0 0 10px;
}

.carousel .control-dots {
  bottom: -25px;
  left: 34px;
  width: auto;
  margin: 0;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 7.4;
}

.carousel .thumbs-wrapper {
  display: none;
}

.name-under-slader {
  margin: 12px 0 0 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.slader-item {
  position: relative;
  width: 100%;
  padding-bottom: 70%;
  overflow: hidden;
}

.ImgFit {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  > img {
    display: none;
  }
}

.button-under-carousel {
  position: relative;
  margin-top: 9px;
  display: flex;
  justify-content: flex-end;
}

.item-bt-under-carousel {
  margin-left: 9px;
  cursor: pointer;
}

.share:hover *,
.pdf:hover *,
.print:hover * {
  fill: #fff;
}

.button-instand {
  cursor: pointer;
  height: 22px;
  width: 90px;
  background: #1e2044;
  border: 1px solid #323054;
  border-radius: 3px 0 0 3px;
  filter: drop-shadow(20px 0px 20px rgba(0, 0, 0, 0.051));
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #fa544d;
}

.button-instand-info {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 22px;
  width: 22px;
  background: #15173c;
  border: 1px solid #323054;
  border-left: none;
  border-radius: 0 3px 3px 0;
  filter: drop-shadow(20px 0px 20px rgba(0, 0, 0, 0.051));
  color: #fa544d;
}

.bt-wrapper {
  margin-top: 12px;
  display: flex;
}

.button-instand-info:hover *,
.button-instand:hover {
  color: #fff;
  fill: #fff;
}

.content-under-carousel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circle-bg:hover * {
  fill: #fff;
}

.top-riting {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.conditions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.condition {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  align-items: center;
}

.conditions-name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8186af;
}

.conditions-sum {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100px;
  background: #1e2044;
  border-radius: 5px;
  border: 1px solid #323054;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

.details {
  margin-top: 20px;
  border-top: 1px solid #323054;
  border-bottom: 1px solid #323054;
}

.details-name {
  display: flex;
  align-items: center;
}

.map {
  display: flex;
  align-items: center;
}

.details-row {
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.detail {
  display: flex;
  justify-content: flex-end;
}

.detail {
  text-align: end;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  max-width: 180px;
}

.detail-name {
  margin-left: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8186af;
}

.devider-end-detail {
  border-top: 1px solid #323054;
}

.super-button {
  display: flex;
  justify-content: space-between;

  button {
    margin: 0 5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
