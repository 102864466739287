.app .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
.app .css-1q2h7u5.Mui-selected {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #fa544d;
}

.app .css-1aquho2-MuiTabs-indicator,
.app .css-ttwr4n {
  background-color: #fa544d;
}

.app .css-1h9z7r5-MuiButtonBase-root-MuiTab-root,
.app .css-1q2h7u5 {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #8186af;
}

.box-NewAndHotProjects {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-1xr9krm.Mui-selected {
  color: #fa544d !important;
}

@media (max-width: 598px) {
  .box-NewAndHotProjects {
    display: block;
  }

  .app .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
  .app .css-1q2h7u5.Mui-selected {
    font-size: 18px;
  }

  .app .css-1h9z7r5-MuiButtonBase-root-MuiTab-root,
  .app .css-1q2h7u5 {
    font-size: 18px;
  }

  .app .css-wjh20t-MuiPagination-ul,
  .app .css-nhb8h9 {
    display: flex;
    justify-content: flex-end;
    margin-top: 23px;
  }
}
