.custom-modal.MuiModal-root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: auto;
}

.custom-modal .MuiBackdrop-root.MuiModal-backdrop {
  backdrop-filter: blur(10px);
  background: rgb(28 23 60 / 55%);
}

.info {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 22px;
  background: #1e2044;
  border: 1px solid #323054;
  border-radius: 5px;
}

.left-title {
  padding: 2px 0 4px 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8186af;
}

.rigth-title {
  padding-right: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

.s-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 445px;
  max-height: 80vh;
  overflow: auto;
  background-color: #13153a;
  border: 1px solid #323054;
  box-shadow: 0px 20px 15px rgba(0, 0, 0, 0.102);

  &__top {
    display: flex;
    justify-content: space-between;
    height: 40px;
    background: #1e2044;
    border-bottom: 1px solid #323054;

    button {
      margin: 6px 25px 6px 0;
    }
  }

  &__logo {
    margin: 9px 0 0 20px;
  }

  &__title {
    margin-top: 30px;
    text-align: center;
  }

  &__main-content {
    padding: 20px 20px 50px 20px;
  }

  &__input-modal {
    margin-bottom: 30px;
    &::placeholder {
      color: #8186af !important;
    }
  }

  &__bt {
    margin-top: 59px;
  }
}

.inp-placeholder::placeholder {
  color: #8186af !important;
  font-size: 16px !important;
  line-height: 16px !important;
}

@media (max-width: 500px) {
  .s-modal {
    width: 320px;
  }
}
