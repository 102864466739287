.boxs-chart__wallet {
  .grid-table-trans {
    display: grid;
    grid-template-columns: 50px 180px 120px 110px 110px;
    grid-column-gap: 110px;
  }

  .grid-table-trans-title {
    display: grid;
    grid-template-columns: 63px 185px 99px 96px 110px;
    grid-column-gap: 120px;
    margin-top: 30px;
    margin-bottom: 17px;
  }

  .skeleton-investment {
    display: grid;
    grid-template-columns: 50px 180px 120px 110px 110px;
    grid-column-gap: 110px;
  }

  .arrow {
    right: 10px !important;
  }
}
