.skeleton-main-news-item {
  &__img {
    max-width: 100%;
    min-height: 250px;
  }
}

.sk-main-content {
  width: 1024px;
}

.mt-20 {
  margin-top: 20px;
}

@media (max-width: 400px) {
  .comp-news-btn {
    &__center {
      min-width: 280px;
    }
  }
}
