.head-right {
  text-align: right;
}
.dlendo-rating,
.rating-wrapper {
  top: 0;
}
.dlendo-rating {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  &__info {
    display: flex;
    margin-left: 10px;
  }
  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #8186af;
    height: 28px;
    min-width: 54px;
    border: 1px solid #323054;
    border-left: none;
    background: #1e2044;
    border-radius: 0 5px 5px 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding: 5px 0 6px 0;
  }
  &__mark {
    height: 28px;
    min-width: 28px;
    border: 1px solid #323054;
    background: #1e2044;
    border-radius: 5px 0 0 5px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #fa544d;
    text-align: center;
    padding: 5px 0 6px 0;
  }
  &__container {
    display: flex;
    margin-top: 6px;
  }

  &__table {
    width: 100%;
    margin-top: 37px;
    overflow: auto;
  }
  &__head {
    display: grid;
    grid-template-columns: 220px 317px 145px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #8186af;
    margin: 0 0 10px 30px;
  }

  &__list {
    display: grid;
    grid-template-columns: 220px 306px 209px;
    grid-row-gap: 10px;
    width: 100%;
  }
  &__row-body {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    height: 28px;
    background: #1e2044;
    border-top: 1px solid #323054;
    border-bottom: 1px solid #323054;
    color: #8186af;
    padding: 5px 0 5px 30px;
    height: 28px;
  }

  &__footer {
    display: grid;
    grid-template-columns: 276px 313px 91px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #8186af;
    margin: 10px 30px 50px 30px;

    &:last-child {
      text-align: right;
    }
  }
  &__row-footer:first-child {
    text-align: left;
  }
}

.first-child-row {
  border-radius: 5px 0 0 5px;
  border-left: 1px solid #323054;
  padding-left: 30px;
}
.last-child-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 5px 5px 0;
  border-right: 1px solid #323054;
  padding-right: 30px;
}

.rating-three {
  padding: 2.5px 5px;
  display: flex;
  width: 110px;
  height: 13px;
  border: 1px solid #323054;
  border-radius: 10px;
  &__item {
    width: 30px;
    height: 6px;
    background: #323054;
    border-radius: 10px;
  }
  .item_second {
    margin: 0 5px;
  }
}

.rating-three-one {
  padding: 2.5px 5px;
  display: flex;
  width: 110px;
  height: 13px;
  border: 1px solid #323054;
  border-radius: 10px;
  &__item {
    width: 30px;
    height: 6px;
    background: #323054;
    border-radius: 10px;
  }
  .item_first {
    background: #fa544d;
  }
  .item_second {
    margin: 0 5px;
  }
}

.rating-three-two {
  padding: 2.5px 5px;
  display: flex;
  width: 110px;
  height: 13px;
  border: 1px solid #323054;
  border-radius: 10px;
  &__item {
    width: 30px;
    height: 6px;
    background: #323054;
    border-radius: 10px;
  }
  .item_first {
    background: #fa544d;
  }
  .item_second {
    margin: 0 5px;
    background: #fa544d;
  }
}

.rating-three-three {
  padding: 2.5px 5px;
  display: flex;
  width: 110px;
  height: 13px;
  border: 1px solid #323054;
  border-radius: 10px;
  &__item {
    width: 30px;
    height: 6px;
    background: #323054;
    border-radius: 10px;
  }
  .item_first {
    background: #fa544d;
  }
  .item_second {
    margin: 0 5px;
    background: #fa544d;
  }
  .item_third {
    background: #fa544d;
  }
}

@media screen and (max-width: 968px) {
  .dlendo-rating {
    display: flex;
    flex-direction: column;
    &__container {
      display: flex;
      flex-wrap: wrap;
    }
    &__mark {
      margin-top: 6px;
    }
    &__text {
      margin-top: 6px;
    }
  }
}
