.chart-title {
  display: flex;
  align-items: center;
  gap: 10.5px;
  height: 99px;
  width: 100%;
  background: #13153a;
  border: 1px solid #242648;
  border-radius: 4px;
  padding: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 78px;
    min-height: 78px;
    background: #090b32;
    border-radius: 3px;
  }
  &__divider {
    width: 100%;
    height: 1px;
    background: #323054;
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 6px;
    div:first-child {
      padding-bottom: 2px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      color: #8186af;
    }
    div:last-child {
      height: 10px;
      margin-bottom: 11px;
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 25px;
    div:first-child {
      font-family: "Kanit";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      color: #8186af;
    }
    div:last-child {
      font-family: "Kanit";
      font-style: normal;
      font-weight: 100 !important;
      font-size: 24px;
      line-height: 36px;
      color: #43fcff;
    }
  }
}
