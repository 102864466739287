.income {
  &__container {
    margin-top: 20px;
    margin-bottom: -65px;
  }
  &__item {
    position: relative;
    z-index: 0;
    padding: 4.5px 15px;
    margin-top: 16px;
    &:first-child {
      margin: 0;
    }
  }
  &__title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8186af;
  }
  &__return {
    margin-top: 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #8186af;
    &:before {
      content: "* ";
      color: #fa544d;
    }
  }
  &__expected {
    margin-top: 1px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #8186af;
    &:before {
      content: "** ";
      color: #fa544d;
    }
  }
}

.chart-input {
  &__position {
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  margin-top: 10px;
  width: 100%;
  &__line {
    margin-top: 5px;
    width: 100%;
  }
  &__wrapper-text {
    margin-bottom: -5px;
    display: flex;
    justify-content: space-between;
  }
  &__text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #43fcff;
    &:last-child {
      &::after {
        content: "**";
        color: #fa544d;
      }
    }
  }
  &__slider {
    margin-bottom: 5px;
    display: flex;
    justify-self: center;
    align-items: center;
    height: 15px;
    width: fit-content;
    border: 1px solid #323054;
    background: #1e2044;
    border-radius: 10px;
    padding: 1px 7px;
  }
  &__title-amount {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #43fcff;
    span {
      color: #fa544d;
    }
  }
}

.chart-annual {
  margin-top: 10px;
}

.custom-slider {
  margin: 4px 0 7px 0;
  width: 100%;
  height: 10px;
  background: rgb(129 134 175 / 12%);
  border-radius: 2px;
  overflow: hidden;
}

.slider-track {
  width: 100%;
  height: 100%;
}

.slider-fill {
  height: 100%;
  background: #fa544d;
  transition: width 2s;
  border-radius: 2px;
}
