.deal-highlights {
  margin-top: 20px;

  &:first-child {
    margin-top: 10px;
  }
  &__title-wrapper {
    display: flex;
    align-items: center;
  }
  &__title {
    margin-left: 21px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #fa544d;
  }
  &__description {
    margin: 9px 0 0 49px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: #8186af;
  }
}
