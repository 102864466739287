.tel-verification {
  position: relative;
  width: 100%;
  &__input {
    position: absolute;
    left: 10px;
    bottom: 3px;
    width: 90%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
  label {
    position: absolute;
    top: 6px;
    left: 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8186af;
    width: max-content;
  }
}

.attention-label {
  &__tel-verification {
    position: relative;
    left: 114px !important;
    top: 2px !important;
  }
}

.data-input {
  &__icon-right_tel-verification {
    position: static;
    width: 70%;
  }
}

.code-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.code-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.code-inputs input {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  margin-right: 9px;
  border-bottom: 1px solid #323054;
  height: 22px;
  width: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
}
.code-inputs input:focus {
  outline: none;
}
