.location {
  margin-bottom: 50px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

/* Стили для кнопок управления зумом */
.gm-control-active > div:not(.gm-control-active),
.gm-control-active > div:not(.gm-control-active):hover,
.gm-control-active > div:not(.gm-control-active):active,
.gm-control-active > div:not(.gm-control-active):focus {
  background-color: #ff0000; /* задаем цвет фона при наведении, активации и фокусировке */
  color: #ffffff; /* задаем цвет текста при наведении, активации и фокусировке */
  border-color: #ff0000; /* задаем цвет рамки при наведении, активации и фокусировке */
  outline: none; /* убираем обводку при фокусировке */
}

/* Стили для кнопки управления зумом в режиме выключенном */
.gm-control > div:not(.gm-control-active),
.gm-control > div:not(.gm-control-active):hover,
.gm-control > div:not(.gm-control-active):active,
.gm-control > div:not(.gm-control-active):focus {
  background-color: #ffffff; /* задаем цвет фона в обычном режиме */
  color: #000000; /* задаем цвет текста в обычном режиме */
  border-color: #000000; /* задаем цвет рамки в обычном режиме */
  outline: none; /* убираем обводку при фокусировке */
}
