.box-chart {
  padding: 15px;
  height: 190px;
  margin-top: 20px;
}

.title-rortfolio {
  margin-left: 13px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8186af;
}
.chart-container {
  margin: 0 0 0 9px;
  padding-top: 20px;
}
.chart-partfolio {
  position: relative;
  z-index: 100;
  width: 100px;
  min-height: 100px;
  &__color {
    position: absolute;
    left: 46px;
    top: -4px;
    width: 8px;
    height: 8px;
    border-radius: 1px;
  }
  &__amount {
    position: absolute;
    left: 30px;
    margin-top: 11px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #43fcff;
  }
  &__wrapper {
    width: 100px;
    position: absolute;
    text-align: center;
    top: 93px;
  }
  &__circle {
    position: absolute;
    top: -25px;
    left: 17px;
    width: 65px;
    min-height: 65px;
    border: 0.5px solid #323054;
    border-radius: 100%;
  }
  &__circle-outside {
    position: absolute;
    top: -48px;
    left: -6px;
    width: 110px;
    min-height: 110px;
    border: 0.5px solid #323054;
    border-radius: 100%;
  }
}

.all-project {
  width: 100%;
  margin-top: 10px;

  &__name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    color: #8186af;
    padding: 0 10px 0 20px;
    margin-top: 11px;
  }
  &__item {
    display: flex;
    background: #15173c;
    border: 1px solid #323054;
    height: 18px;
    width: 100%;
    border-radius: 20px;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 18px;
    border-right: 1px solid #323054;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 4px 8px 4px 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #43fcff;
  }
  &__all-name {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #8186af;
  }
  .color-item {
    width: 8px;
    height: 8px;
    border-radius: 1px;
    background: #323054;
    &:hover {
      &::before {
        content: "";
        display: block;
        position: relative;
        top: -3px;
        left: -3px;
        width: 13px;
        height: 13px;
        border-radius: 2px;
        border: 1.5px solid #43fcff;
      }
    }
  }
  &__color {
    margin-top: 11px;
    display: flex;
    gap: 11px;
  }

  &__color-item {
    display: flex;
    gap: 6px;
  }
}

.both-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.wrapper-item-color {
  position: relative;
}
