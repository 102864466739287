.slider-level {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #8186af;
}

.text-procent-value {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #fe5461;
}

.download-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  gap: 10px;
  border: 1px solid #43fcff;
  background: #090b32;
  color: #43fcff;

  &:hover {
    background: #43fcff;
    color: #fff;
    border: 1px solid #aefeff;
  }
}

.slider-description {
  margin-top: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #8186af;
}

.MuiSlider-sizeMedium {
  padding: 0;
}

@media (max-width: 425px) {
  .slider-description {
    font-size: 12px;
    line-height: 18px;
  }
}
