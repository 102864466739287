.info-popup {
  border: 1px solid #323054;
  background: #1e2044;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  &__info {
    padding: 9px 15px 12px 15px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #8186af;
  }
}
