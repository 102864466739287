.projec-news {
  display: flex;

  &__img {
    img {
      width: 96px;
      height: 68px;
      object-fit: cover;
    }
  }
  &__content {
    height: 68px;
    width: 286.5px;
    left: 620px;
    top: 1032px;
    border-radius: 0px;
    background: #1e2044;
    border: 1px solid #323054;
    padding: 8.4px 14.6px 14px 14.7px;
  }
  &__wrapper-number-and-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__number {
    padding: 0.4px 10px 1.6px 10px;
    height: 14px;
    border: 1px solid #323054;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #fa544d;
    border-radius: 10px;
    text-align: center;
  }
  &__date {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #8186af;
  }
  &__title {
    margin: 15px 0 0 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
}

@media (max-width: 768px) {
  .project-new__mt {
    margin-top: 95px;
  }
}
