.map-chart {
  width: 100%;
  max-height: 263px;
  overflow: hidden;
  border: 1px solid #242648;
}
.select-map-chart {
  display: flex;
  justify-content: space-between;
  gap: 100px;
  min-height: 45px;
  .panel-info__title {
    line-height: 8px;
  }
  .chart-partfolio__color {
    position: static;
    background: #323054;
    width: 11px;
    height: 11px;
    border-radius: 50px;
  }
}

.custom-selects__map-chart {
  .status-input__title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #43fcff;
  }

  img {
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding-top: 2px;
  }
}

@media (max-width: 425px) {
  .select-map-chart {
    gap: 10px;
  }
}
