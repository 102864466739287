.MuiStack-root {
  width: 100% !important;
}

.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-outlined.MuiPaginationItem-circular.MuiPaginationItem-page {
  background: #323054;
  color: #8186af;
}
.MuiPaginationItem-outlined.MuiPaginationItem-circular.MuiPaginationItem-page.Mui-selected {
  color: #fa544d !important;
}

.MuiPaginationItem-previousNext {
  height: 24px !important;
  min-width: 24px !important;
  background: #323054 !important;
}

.home-map {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 22px;
    h2 {
      margin: 0;
    }
  }
  &__label {
    display: flex;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    &-name {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
  }
}

.map__wrapper {
  margin-top: 30px;
}

@media (max-width: 598px) {
  .home-map {
    &__wrapper {
      display: block;
    }
    &__label {
      justify-content: flex-end;
      margin-top: 18px;
    }
  }
}

.all-select {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &__amount {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #090b32;
    border: 1px solid #323054;
    height: 28px;
    border-radius: 5px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #8186af;
    min-width: 160px;
    cursor: pointer;
  }

  &__item {
    margin: 5px;
  }

  &__bt-title {
    padding: 5px 0 3px 0;
  }

  &__bt-cancel {
    position: relative;
    right: 7px;
    top: -0.5px;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 1.5px;
      background: #8186af;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  &__divider {
    position: relative;
    left: 20px;
    height: 100%;
    width: 1.5px;
    background: #323054;
  }

  .active-amount {
    background: #fa544d;
    color: #ffffff;

    .all-select__bt-cancel {
      &:before,
      &:after {
        background: #ffffff !important;
      }
    }
    .all-select__divider {
      background: #cccccc;
    }
  }
}

.all-cards {
  margin-top: 30px;
  &__pagination-and-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 66px !important;
  }

  &__button {
    background: #1e2044;
    border: 1px solid #323054;
    height: 36px;
    border-radius: 5px;
    outline: none;
    padding: 9px 37px 10px 37px;
    color: #fa544d;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    &:hover {
      background: #fa544d;
      color: #ffffff;
    }
  }
}

@media (max-width: 768px) {
  .all-cards {
    &__pagination-and-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-bottom: -30px !important;
    }

    &__button {
      height: 36px;
      outline: none;
      padding: 9px 37px 10px 37px;
      margin-top: 20px;
      margin-bottom: 20px;
      &:hover {
        background: #fa544d;
        color: #ffffff;
      }
    }
  }
}
