.ratio-panel-info {
  display: flex;
  width: 100%;
  height: 13px;
  border: 1px solid #242648;
  border-radius: 3px;
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 17px;
    max-height: 13px;
    border-right: 1px solid #242648;
  }
  &__title {
    padding: 0 9px 1px 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #43fcff;
  }
}

.ratio-panel-info__wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 15px;
}
.chart-polar-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
