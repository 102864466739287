.page-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 1005px;
  h1 {
    color: #fa544d;
    font-size: 100px;
  }
  h2 {
    font-size: 22px;
  }
  div {
    text-align: center;
  }
}
