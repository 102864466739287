.profiable-titles-table {
  display: grid;
  grid-template-columns: 114px 194px 58px 166px 102px 105px;
  grid-column-gap: 60px;
  margin: 15px 0 27px 0;
}

.profiable-table {
  display: grid;
  grid-template-columns: 100px 200px 60px 170px 105px 105px;
  grid-column-gap: 55px;
  align-items: center;
  height: 48px;
  width: 1065px;
  border: 1px solid #323054;
  background: #1e2044;
  border-radius: 5px;
  margin-top: 10px;
  padding: 7px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8186af;

  &__Project {
    display: flex;
    align-items: center;
    &-img {
      display: flex;
      align-items: center;
      img {
        width: 28px;
        height: 28px;
        object-fit: cover;
        border-radius: 4px;
      }
    }
    &-title {
      margin-left: 10px;
    }
  }

  &__Distribution {
    display: flex;
    align-items: center;
    &-title {
      margin-left: 10px;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }

  &__Return {
    display: flex;
    align-items: center;
    &-title {
      margin-left: 10px;
      color: #43fcff;
    }
    &-icon {
      display: flex;
      align-items: center;
    }
  }

  &__Investment {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &-title {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      div:first-child {
        color: #43fcff;
      }
      div:last-child {
        margin-top: 4px;
      }
    }
    &-icon {
      margin-top: 4px;
    }
  }

  &__Funding {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &-title {
      color: #43fcff;
      span {
        color: #fa544d;
      }
    }
    .range-castom {
      margin-top: 5px;
      width: 150px;
      height: 5px;
      border-radius: 20px;
      background: rgb(129 134 175 / 12%);
      overflow: hidden;
      &__track {
        width: 100%;
        height: 100%;
      }
      &__fill {
        height: 100%;
        background: #fa544d;
        transition: width 2s;
        border-radius: 5px;
      }
    }
  }
}

@keyframes loading {
  to {
    opacity: 0.5;
  }
}

.s-profiable-table {
  display: grid;
  grid-template-columns: 100px 200px 60px 170px 105px 105px;
  grid-column-gap: 55px;
  align-items: center;
  height: 48px;
  width: 1065px;
  border: 1px solid #323054;
  background: #1e2044;
  border-radius: 5px;
  margin-top: 10px;
  padding: 7px 20px;
  color: #8186af;
}
