.label-map {
  position: relative;
  display: flex;
  &__img {
    img {
      height: 85px;
      width: 112px;
      object-fit: cover;
    }
  }
  &__content {
    height: 85px;
    width: 218px;
    border-radius: 0px;
    background: #1e2044;
    border: 1px solid #323054;
    padding: 10px;
  }
  &__wrapper-number-and-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__conditions {
    margin: 11px 0 0 0;
    padding: 1px 10px 3px 10px;
    height: 18px;
    border: 1px solid #323054;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    border-radius: 10px;
    text-align: center;
    &:first-child {
      color: #fa544d;
    }
  }
  &__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
  &__funded {
    margin-top: 7px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #fa544d;
    &::after {
      content: " funded";
      color: #fff;
    }
  }
  &__buttons {
    position: absolute;
  }
  &__button {
    margin: 5px;
  }
}
