.project-costing {
  &__first-container {
    display: grid;
    grid-template-columns: 350px 1fr 1fr;
    grid-row-gap: 10px;
    overflow: auto;
    margin: 19px 0 11px 0;
    width: 100%;
  }

  &__row {
    padding: 5px 32px 6px 30px;
    height: 28px;
    background: #1e2044;
    border-top: 1px solid #323054;
    border-bottom: 1px solid #323054;
    color: #8186af;
  }
  .first-child {
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #323054;
    padding-left: 30px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #8186af;
  }

  .last-child {
    display: flex;
    justify-content: flex-end;
    border-radius: 0 5px 5px 0;
    border-right: 1px solid #323054;
    padding-right: 30px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
  }
  &__second-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 48px;
  }
  &__total-investment {
    margin-right: 71px;
    padding: 3px 0 0 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }
  &__amount {
    margin-right: 30px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #43fcff;
  }
}
