.chart-title__wallet {
  .chart-title {
    &__bottom {
      margin-top: 10px;
    }
    &__divider {
      position: relative;
      top: -6px;
    }
    &__top {
      position: relative;
      top: -6px;
    }
  }
}

.btn-greey__wallet {
  color: #43fcff;
  border: 1px solid #43fcff;
  &:hover {
    background: #43fcff;
    color: #fff;
    border: 1px solid #aefeff;
  }
}

.btn-red__wallet {
  color: #fa544d;
  &:hover {
    color: #fff;
  }
}
